@tailwind base;
@tailwind components;
@tailwind utilities;

#mainBottom {
    height: calc(100% - 150px);
}

.scrl {
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .main {
    height: calc(100vh - 108px);
 }
 
 .plainBtn {
    @apply py-1 px-4 border border-transparent rounded-md shadow-sm text-sm font-semibold;
  }
  
  .rawBtn {
    @apply  border-solid rounded-md shadow-sm font-semibold;
  }
  
  .subBtn {
    @apply w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700;
  }
  
  .okBtn {
    @apply w-[100px] flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm  font-semibold text-black bg-green-400 hover:bg-green-700;
  }
  
  .cancelBtn {
    @apply w-[100px] flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm  font-semibold text-black bg-slate-400 hover:bg-slate-700;
  }

  .formText {
    @apply w-full border-gray-300 rounded-lg shadow-sm;
  }

  .mcard {
    word-wrap: break-word;
    background-clip: border-box;
    /* background-color: #fff; */
    /* border: 1px solid rgba(0,0,0,.125); */
    border-radius: .25rem;
    min-width: 0;
    flex: 1 1 auto;
  }
  
  .mcard-header {
    height: 40px;
    margin: 0;
    padding: 4px 16px;
    color: green;
    background-color: rgba(0,0,0,.03);
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.2;
    border-bottom: 1px solid rgba(0,0,0,.125);
    /* border-radius: .25rem .25rem 0 0; */
    @apply flex items-center justify-between w-full;
  }
  
  .mhBtn {
    @apply rounded-md border-0 px-2 py-1 text-black bg-gray-300 text-center cursor-pointer no-underline;
    font-size: 20px;
    font-weight: 500;
    vertical-align: middle;
  }
  
  .mcard-title {
    padding: 0;
    font-size: 1.25rem;
    font-weight: 500;
    display: flex;
    height: 75px;
    width: 100%;
    align-items: center;
  }
  
  .mcard-subtitle {
    padding: 16px;
    font-size: 1.rem;
    font-weight: 500;
    margin-bottom: .5rem;
  }
  
  
  .mrow {
    display: flex;
    flex-wrap: wrap;
  }
  
  .mcol7 {
    flex: 0 0 auto;
    width: 60%;
  }
  
  .mcol {
    flex: 1 0;
  }
  
  .lcard {
    @apply px-2 mx-1;
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100vh - 142px);
  }

  /* buttons */

.ddBtn {
  @apply px-4 py-2 text-lg text-gray-700 hover:bg-indigo-700 hover:text-white;
}

.mbtn {
  @apply rounded-md border-0 p-2 text-black text-center font-bold cursor-pointer no-underline;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hpBtn {
  @apply py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700;
}

.plainBtn {
  @apply py-1 px-4 border border-transparent rounded-md shadow-sm text-sm font-semibold;
}

.rawBtn {
  @apply  border-solid rounded-md shadow-sm font-semibold;
}

.subBtn {
  @apply w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700;
}

.okBtn {
  @apply w-[100px] flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm  font-semibold text-black bg-green-400 hover:bg-green-700;
}

.cancelBtn {
  @apply w-[100px] flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm  font-semibold text-black bg-slate-400 hover:bg-slate-700;
}

.histItem {
  @apply font-bold p-4 text-base small:text-lg phone:text-xl tablet:text-2xl flex items-center justify-between w-full;
}

  
  
  